<template>
  <el-card class="box-card">
    <div slot="header">
      <span>
        {{ $t('create') }}
        {{ $tc('entity.newsletter', 1) }}
      </span>
    </div>
    <el-row :gutter="20">
      <el-col :sm="12">
        <el-alert
          title="Se envia email a todos los usuarios"
          type="info"
          show-icon
          style="margin-bottom: 8px"
          :closable="false"
        >
          <code>
            <br />
            -userEmail-
            <br />
            -userFirstName-
            <br />
            -userLastName-
          </code>
        </el-alert>
        <el-card v-loading="loading" class="box-card primary" shadow="never">
          <div slot="header">
            <span>E-mail</span>
          </div>
          <el-form
            ref="formNewsletter"
            v-loading="loading"
            :model="newsletter"
            label-position="top"
            :rules="rulesNewsletter"
          >
            <el-form-item
              :label="$tc('template', 1)"
              prop="newsletterTemplateId"
            >
              <el-select v-model="newsletter.newsletterTemplateId">
                <el-option
                  label="Newsletter 1.0"
                  value="00a07681-1187-4772-9344-e89a8e4d2548"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('subject')" prop="newsletterSubject">
              <el-input
                v-model="newsletter.newsletterSubject"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('content')" prop="newsletterContent">
              <tinymce v-model="newsletter.newsletterContent" :height="800" />
            </el-form-item>
            <el-button size="mini" type="primary" @click="save">
              {{ $t('save') }}
            </el-button>
          </el-form>
        </el-card>
      </el-col>
      <el-col :sm="12">
        <el-card v-loading="loading" class="box-card primary" shadow="never">
          <div slot="header">
            <span>E-mail de prueba</span>
          </div>
          <el-form
            ref="formNewsletterTest"
            v-loading="loading"
            :model="newsletterTest"
            label-position="top"
            :rules="rulesNewsletter"
          >
            <el-form-item
              :label="$tc('template', 1)"
              prop="newsletterTemplateId"
            >
              <el-select v-model="newsletterTest.newsletterTemplateId">
                <el-option
                  label="Newsletter 1.0"
                  value="00a07681-1187-4772-9344-e89a8e4d2548"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('email')" prop="newsletterEmail">
              <el-input
                v-model="newsletterTest.newsletterEmail"
                maxlength="128"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('subject')" prop="newsletterSubject">
              <el-input
                v-model="newsletterTest.newsletterSubject"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('content')" prop="newsletterContent">
              <tinymce
                v-model="newsletterTest.newsletterContent"
                :height="800"
              />
            </el-form-item>
            <el-button size="mini" type="primary" @click="saveTest">
              {{ $t('save') }}
            </el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { i18n } from '../../i18n'

import Tinymce from '@/components/tinymce'

export default {
  components: { Tinymce },
  data() {
    return {
      newsletter: {
        newsletterTemplateId: '00a07681-1187-4772-9344-e89a8e4d2548',
      },
      newsletterTest: {
        newsletterTemplateId: '00a07681-1187-4772-9344-e89a8e4d2548',
      },
      toolbar:
        'fullscreen formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
      plugins:
        'advcode fullscreen image link media table charmap hr toc advlist lists imagetools textpattern',
      rulesNewsletter: {
        newsletterTemplateId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        newsletterSubject: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        newsletterContent: [
          {
            required: true,
            message: i18n.t('required'),
            trigger: ['blur', 'change'],
          },
        ],
        newsletterEmail: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
          {
            type: 'email',
            message: i18n.t('invalidEmail'),
            trigger: ['blur', 'change'],
          },
        ],
      },
      loading: false,
    }
  },
  computed: {
    roleId() {
      return JSON.parse(localStorage.getItem('masters')).roleId
    },
    userReadOnly() {
      return this.roleId.localeCompare('1') === 0
    },
  },
  mounted() {
    // this.get()
  },
  methods: {
    save() {
      const _this = this
      _this.$refs.formNewsletter.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http.post('newsletters', _this.newsletter).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.newsletter = {}
              _this.loading = false
            },
            response => {
              _this.loading = false
            },
          )
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
    saveTest() {
      const _this = this
      _this.$refs.formNewsletterTest.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http.post('newsletters', _this.newsletterTest).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.newsletterTest = {}
              _this.loading = false
            },
            response => {
              _this.loading = false
            },
          )
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
  },
}
</script>
